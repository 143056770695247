import styled from "styled-components";
import { SubTitle, Title } from "./StyledComponent";
import { Description } from "./StyledComponent";
import { Input } from "./StyledComponent";
import { Button } from "./StyledComponent";
import { BreakPoint } from "./StyledComponent";
import React from "react";
import { Box } from "./StyledComponent";
import { useNavigate } from "react-router-dom";
import {
  LuVoicemail,
  LuTag,
  LuMessageSquare,
  LuSignal,
  LuPhone,
  LuCalendar,
} from "react-icons/lu";
import { colors } from "./Constants";
const PlansDetail = () => {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/personalInfo");
  };
  return (
    <div className="container">
      <Title>Plan detail </Title>
      <PlanPrice>$70.00 per Month</PlanPrice>

      <SectionTitle>Allowances</SectionTitle>
      <Allowances>
        <Allowance>
          <AllowanceIcon>
            <LuSignal /> Data:
          </AllowanceIcon>
          <AllowanceValue>Unlimited</AllowanceValue>
        </Allowance>
        <Allowance>
          <AllowanceIcon>
            <LuVoicemail /> Voice
          </AllowanceIcon>
          <AllowanceValue>Unlimited</AllowanceValue>
        </Allowance>
        <Allowance>
          <AllowanceIcon>
            <LuMessageSquare /> Text Messages
          </AllowanceIcon>
          <AllowanceValue>Unlimited</AllowanceValue>
        </Allowance>
      </Allowances>

      <SectionTitle>Plan Overview</SectionTitle>
      <PlanOverview>
        <OverviewItem>
          <OverviewLabel>
            <LuTag /> Price:
          </OverviewLabel>
          <OverviewValue>$70.00</OverviewValue>
        </OverviewItem>
        <OverviewItem>
          <OverviewLabel>
            <LuCalendar /> Billing Period:
          </OverviewLabel>
          <OverviewValue>1 Month</OverviewValue>
        </OverviewItem>
        <OverviewItem>
          <OverviewLabel>
            <LuPhone /> Phone Number:
          </OverviewLabel>
          <OverviewValue>Included</OverviewValue>
        </OverviewItem>
      </PlanOverview>

      <Button onClick={handleNext}>Select Plan </Button>
    </div>
  );
};

const PlanPrice = styled.p`
  font-size: 18px;
  text-align: center;
  color: ${colors.primary};
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  margin-top: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
  border-top: 1px solid ${colors.division};
  padding-bottom: 5px;
`;

const Allowances = styled.div`
  margin-bottom: 20px;
`;

const Allowance = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const AllowanceIcon = styled.span`
  margin-right: 15px;
`;

const AllowanceValue = styled.span`
  display: flex;
  justify-content: space-between;
`;
const PlanOverview = styled.div`
  margin-bottom: 20px;
`;

const OverviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const OverviewLabel = styled.span`
  font-weight: bold;
`;

const OverviewValue = styled.span`
  color: ${colors.primary};
`;

export default PlansDetail;
