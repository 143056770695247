// for testing: ?? const BASE_API_URL = "http://localhost:8086/api/";
// const SIM_ACTIVATION_API_URL = "https://phoneboxapi.azurewebsites.net";
// baseURL: "https://phoneboxapi.azurewebsites.net",
// baseURL:"https://lightphonemyaccount-dmcfeycncpcphqen.canadacentral-01.azurewebsites.net/",
import axios from "axios";

//SECTION - FOR Register and login:
const IDENTITY_API_URL =
  "https://identityserver20231003125552.azurewebsites.net/api/";

// const BASE_API_URL = "https://testmyaccount.azurewebsites.net/api/";
// const SHARED_API_URL = "https://testmyaccount.azurewebsites.net";
// const SIMCARD_API_URL = "https://lightphonemyaccount.beonesys.com/";

//FIXME - this is dev
const SHARED_API_URL =
  "https://lightphonemyaccount-dmcfeycncpcphqen.canadacentral-01.azurewebsites.net";

export const identityInstance = axios.create({ baseURL: IDENTITY_API_URL });
export const sharedInstance = axios.create({
  baseURL: SHARED_API_URL,
  timeout: 60000,
  headers: {
    apitoken: "75e142e9fd9decba5da64d86874ce5b6",
    client: "alpha",
  },
});

// export const instance = axios.create({ baseURL: BASE_API_URL });
// export const simcardInstance = axios.create({
//   baseURL: SIMCARD_API_URL,
//   timeout: 60000,
//   headers: {
//     apitoken: "75e142e9fd9decba5da64d86874ce5b6",
//     client: "alpha",
//   },
// });
