import { Title, SubTitle, SectionContainer } from "./StyledComponent";
import { Button } from "./StyledComponent";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const Summary = () => {
  const navigate = useNavigate();
  const { summaryDetails, planDetails } = useSelector(
    (state) => state.userInfo
  );
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    }).format(new Date(date));
  };
  const getEndDate = () => {
    const today = new Date();
    const endDate = new Date(today.setDate(today.getDate() + 30));
    return endDate.toISOString().split("T")[0];
  };

  const handleNext = () => {
    navigate("/myplan");
  };

  return (
    <div className="container">
      <Title>Thank you for your purchase!</Title>
      <SubTitle>Order Summary</SubTitle>
      <SectionContainer>
        <TextItem>Name: {summaryDetails?.name}</TextItem>
        <TextItem>Email: {summaryDetails?.email}</TextItem>
        <TextItem>
          Activation Date: {formatDate(summaryDetails.activationDate)}
        </TextItem>
      </SectionContainer>

      <SubTitle>Plan Details</SubTitle>
      <SectionContainer>
        <TextItem>Currency: {planDetails?.currency}</TextItem>
        <TextItem>Plan Amount: {formatCurrency(planDetails.plan_Amt)}</TextItem>
        <TextItem>Duration: {planDetails?.charge_Duration}</TextItem>
        <TextItem>Subtotal: {formatCurrency(planDetails.subtotal)}</TextItem>
        <TextItem>GST: {formatCurrency(planDetails.gst_Amt)}</TextItem>
        <TextItem>PST: {formatCurrency(planDetails.pst_Amt)}</TextItem>
        <TextItem>
          Sim Card Fee: {formatCurrency(planDetails.simcard_amt)}
        </TextItem>
        <TextItem>Total: {formatCurrency(planDetails.total)}</TextItem>
      </SectionContainer>

      <Button onClick={handleNext}>My Plans</Button>
    </div>
  );
};
// Styled Components
const TextItem = styled.p`
  font-size: 14px;
  color: #333;
  margin: 5px 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  white-space: normal;
`;
export default Summary;
