import styled from "styled-components";
import { Title, SubTitle } from "./StyledComponent";
import { Description } from "./StyledComponent";
import React from "react";
import {
  InputContainer,
  Input,
  InputLabel,
  SectionContainer,
} from "./StyledComponent";
import { Button } from "./StyledComponent";
import { BreakPoint } from "./StyledComponent";
import { Box } from "./StyledComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sharedInstance } from "../api/axios";
import {
  setFirstName,
  setLastName,
  setEmail,
  setConfirmEmail,
  setProvince,
  setCity,
  setPhoneNumber,
  setCarrier,
  setAccountNumber,
  setImeiNumber,
  setPortIn,
  setSimCardNumber,
  storeUserInfoInLocalStorage,
  setPlanDetails,
  setSummaryDetails,
} from "../api/UserInfoSlice";

const Checkout = () => {
  const navigate = useNavigate();
  const {
    firstName,
    lastName,
    email,
    confirmEmail,
    province,
    city,
    phoneNumber,
    carrier,
    accountNumber,
    imeiNumber,
    portIn,
    simCardNumber,
    planDetails,
    summaryDetails,
  } = useSelector((state) => state.userInfo);
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    }).format(new Date(date));
  };
  const getEndDate = () => {
    const today = new Date();
    const endDate = new Date(today.setDate(today.getDate() + 30));
    return endDate.toISOString().split("T")[0];
  };
  const handleNext = async () => {
    try {
      const response = await sharedInstance.post(
        "/api/Activation/PostActivationInfoPrepaid",
        {
          simcardOrder: false,
          simcard_no: simCardNumber || "",
          startDate: new Date().toISOString().split("T")[0],
          endDate: getEndDate(),
          planId: 1753,
          firstName,
          lastName,
          email,
          serviceType: "M",
          portin_carrier: carrier,
          portin_accountNo: accountNumber,
          portin_phoneNo: phoneNumber,
          portin_IMEI: imeiNumber,
          service_countryId: 42,
          service_province: province,
          service_city: city,
          delivery_countryId: 0,
          delivery_province: "",
          delivery_address: "",
          delivery_postal: "",
          currency: "CAD",
          simcard_fee: 0,
          prorate_fee: 0,
          charge_duration: 0,
          plan_fee: planDetails.plan_Amt,
          gst_rate: planDetails.gst_rate,
          pst_rate: planDetails.pst_rate,
          gst_amt: planDetails.gst_Amt,
          pst_amt: planDetails.pst_Amt,
          subtotal: planDetails.subtotal,
          total: planDetails.total,
          promocode: "",
          promocredit: 0,
          bizId: 0,
          sfID: 0,
          referral_cnum: "",
          consent_cem: true,
          shipping_contact_number: "",
        }
      );

      if (response.status === 200) {
        console.log("checkout response data", response);
        navigate("/payment");
      } else {
        console.error("failed:", response.error);
      }
    } catch (error) {
      console.error("Error occurred during activation:", error);
    }
  };
  return (
    <div className="container">
      <Title>Checkout</Title>

      <SubTitle>Plan Details</SubTitle>
      <SectionContainer>
        <TextItem>Currency: {planDetails?.currency}</TextItem>
        <TextItem>Plan Amount: {formatCurrency(planDetails.plan_Amt)}</TextItem>
        <TextItem>Duration: {planDetails?.charge_Duration}</TextItem>
        <TextItem>Subtotal: {formatCurrency(planDetails.subtotal)}</TextItem>
        <TextItem>GST: {formatCurrency(planDetails.gst_Amt)}</TextItem>
        <TextItem>PST: {formatCurrency(planDetails.pst_Amt)}</TextItem>
        <TextItem>
          Sim Card Fee: {formatCurrency(planDetails.simcard_amt)}
        </TextItem>
        <TextItem>Total: {formatCurrency(planDetails.total)}</TextItem>
      </SectionContainer>

      <SubTitle>Summary</SubTitle>
      <SectionContainer>
        <TextItem>Name: {summaryDetails?.name}</TextItem>
        <TextItem>Email: {summaryDetails?.email}</TextItem>
        <TextItem>
          Activation Date: {formatDate(summaryDetails.activationDate)}
        </TextItem>
      </SectionContainer>

      <Button onClick={handleNext}>Proceed to Payment</Button>
    </div>
  );
};

// Styled Components
const TextItem = styled.p`
  font-size: 14px;
  color: #333;
  margin: 5px 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  white-space: normal;
`;

export default Checkout;
