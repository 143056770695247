import styled from "styled-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Title,
  Input,
  InputContainer,
  InputLabel,
} from "../../components/StyledComponent";
import { useState } from "react";
import { LuRedoDot } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const changeBirthday = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <div className="container">
      <Title>Update Date of Birth</Title>
      <InputContainer>
        <Input />
        <InputLabel>Date of Birth</InputLabel>
      </InputContainer>

      <Button>
        {/* {loading ? <LoadingIcon /> : "Save"} */}
        Save
      </Button>
    </div>
  );
};

const LoadingIcon = styled(LuRedoDot)`
  animation: rotate 1s linear infinite;
  font-size: 1.5rem;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default changeBirthday;
